<template>
  <div class="auth-page">
    <!-- Navigation -->
    <nav class="bg-white shadow-sm fixed w-full z-50 top-0">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="flex justify-between h-16">
                <div class="flex items-center">
                  <img
                    src="/xie-trans.png"
                    width="75"
                    max-width="75"
                    class="d-block"
                  />
                </div>
                <div class="hidden md:flex items-center space-x-8">
                    <a href="#" class="text-gray-700 hover:text-primary">Home</a>
                    <a href="#" class="text-gray-700 hover:text-primary">Services</a>
                    <a href="#" class="text-gray-700 hover:text-primary">Track</a>
                    <a href="#" class="text-gray-700 hover:text-primary">About</a>
                    <a href="#loginto" class="btn-primary white--text px-6 py-2 rounded-full">Get Started</a>
                </div>
            </div>
        </div>
    </nav>

    <!-- Hero Section -->
    <section class="pt-32 pb-32 bg-white">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
                <div>
                    <h1 class="text-4xl md:text-5xl font-bold text-gray-900 mb-6">Fast & Automated Shipping Solutions</h1>
                    <p class="text-lg text-gray-600 mb-2">Say goodbye to the hassle of tracking orders, managing product data, and creating invoices.
                    </p>
                    <p class="text-lg text-gray-600 mb-8">Our system automates every step—from order processing to invoice generation—integrating seamlessly accurate delivery every time.</p>
                    <div class="flex space-x-4">
                        <a href="#loginto" class="btn-primary text-white white--text px-8 py-3 rounded-full">Get Started</a>
                        <button class="border-2 border-primary text-primary px-4 py-3 rounded-full hover:bg-gray-50">Track Package</button>
                    </div>
                </div>
                <div class="flex justify-center">
                    <img src="/banner.png" alt="Delivery Service Illustration" style="width: 300px;">
                </div>
            </div>
        </div>
    </section>

    <!-- Features Section -->
    <section class="py-20 bg-gray-50">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 class="text-3xl font-bold text-center mb-12">Why Choose Us</h2>
            <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
                <div class="bg-white p-8 rounded-lg shadow-md text-center">
                    <div class="w-16 h-16 bg-primary/10 rounded-full flex items-center justify-center mx-auto mb-6">
                        <i class="fas fa-truck text-2xl text-primary"></i>
                    </div>
                    <h3 class="text-xl font-semibold mb-4">Fast Delivery</h3>
                    <p class="text-gray-600">Same-day delivery available for local packages and express shipping nationwide.</p>
                </div>
                <div class="bg-white p-8 rounded-lg shadow-md text-center">
                    <div class="w-16 h-16 bg-primary/10 rounded-full flex items-center justify-center mx-auto mb-6">
                        <i class="fas fa-map-marker-alt text-2xl text-primary"></i>
                    </div>
                    <h3 class="text-xl font-semibold mb-4">Invoice & Tracking</h3>
                    <p class="text-gray-600">Track your packages in real-time with our Invoice generated system.</p>
                </div>
                <div class="bg-white p-8 rounded-lg shadow-md text-center">
                    <div class="w-16 h-16 bg-primary/10 rounded-full flex items-center justify-center mx-auto mb-6">
                        <i class="fas fa-shield-alt text-2xl text-primary"></i>
                    </div>
                    <h3 class="text-xl font-semibold mb-4">Secure Handling</h3>
                    <p class="text-gray-600">Your packages are handled with care and fully insured during transit.</p>
                </div>
            </div>
        </div>
    </section>

    <!-- CTA Section -->
    <section id="loginto" class="py-20 bg-black text-white">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            <h2 class="text-3xl font-bold mb-6">Ready to Ship?</h2>
            <p class="text-lg mb-8 max-w-2xl mx-auto">Join thousands of satisfied customers who trust us with their deliveries.</p>
            <div class="d-flex justify-center align-center pb-16">
              <div class="text-center px-4" style="min-width: 300px;">
                <v-row no-gutters class="justify-center align-center">
                  <v-col cols="12" class="col-sm-8 px-4 d-flex justify-center align-center text-center text-h4 pt-0 pb-8">
                    <v-img
                      src="/img.jpg"
                      size="145"
                      max-width="145"
                    />
                  </v-col>
                  <!-- <v-col cols="12" class="col-sm-8 mt-2 pa-4 justify-center text-center text-h5 mb-4">
                    ABC LOGISTIK
                  </v-col> -->
                </v-row>
                <span
                  class="orange--text font-weight-bold white--text text-h6"
                >
                  - {{ $store.state.isClient ? 'BUSINESS' : 'ADMIN' }} DASHBOARD -
                </span>
                <v-divider class="my-8" />
                <v-text-field
                  v-model="login.username"
                  label="Username"
                  outlined
                  dark
                  hide-details
                  class="mb-4"
                  @keypress.enter="AUTH_LOGIN"
                />
                <v-text-field
                  v-model="login.password"
                  label="Password"
                  type="password"
                  outlined
                  dark
                  hide-details
                  class="mb-8"
                  @keypress.enter="AUTH_LOGIN"
                />
                <v-divider class="mb-4" dark />
                <button class="btn-primary white--text px-6 py-2 rounded-full c-pointer" @click.prevent="AUTH_LOGIN">
                  <v-icon class="mr-2" color="white" small>
                    mdi-account-lock
                  </v-icon>
                  Login Dashboard
                </button>
                <div class="pt-8">
                  Need an access? contact our team via whatsapp <a href="https://api.whatsapp.com/send?phone=6285213311314&text=Hello%20XTI" target="_blank" class="font-weight-bold">+62 852 1331 1314</a>
                </div>
              </div>
            </div>
        </div>
    </section>

    <!-- Footer -->
    <footer class="bg-white py-12">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="grid grid-cols-1 md:grid-cols-4 gap-8">
                <div>
                    <span class="text-2xl font-bold text-primary">Xie<span class="text-black">Trans</span></span>
                    <p class="mt-4 text-gray-600">Professional & smart delivery services you can trust.</p>
                </div>
                <div>
                    <h4 class="text-lg font-semibold mb-4">Quick Links</h4>
                    <ul class="space-y-2 text-gray-600">
                        <li><a href="#" class="hover:text-primary">About Us</a></li>
                        <li><a href="#" class="hover:text-primary">Services</a></li>
                        <li><a href="#" class="hover:text-primary">Tracking</a></li>
                        <li><a href="#" class="hover:text-primary">Contact</a></li>
                    </ul>
                </div>
                <div>
                    <h4 class="text-lg font-semibold mb-4">Services</h4>
                    <ul class="space-y-2 text-gray-600">
                        <li><a href="#" class="hover:text-primary">Express Delivery</a></li>
                        <li><a href="#" class="hover:text-primary">International Shipping</a></li>
                        <li><a href="#" class="hover:text-primary">Bulk Shipping</a></li>
                        <li><a href="#" class="hover:text-primary">Storage Solutions</a></li>
                    </ul>
                </div>
                <div>
                    <h4 class="text-lg font-semibold mb-4">Contact Us</h4>
                    <ul class="space-y-2 text-gray-600">
                        <li><a href="https://api.whatsapp.com/send?phone=6285213311314&text=Hello%20XTI" target="_blank"><i class="fas fa-phone mr-2 text-primary"></i> +62 852 1331 1314</a></li>
                        <li><a href="mailto:info@xti.co.id" target="_blank"><i class="fas fa-envelope mr-2 text-primary"></i> info@xti.co.id</a></li>
                        <li><i class="fas fa-map-marker-alt mr-2 text-primary"></i> 123 Delivery Street</li>
                    </ul>
                </div>
            </div>
            <div class="border-t mt-12 pt-8 text-center text-gray-600">
                <p>&copy; 2024 XieTrans. All rights reserved.</p>
            </div>
        </div>
    </footer>
  </div>
</template>

<script>
export default {
  data: () => ({
    login: {
      username: '',
      password: '',
      business: false
    }
  }),
  methods: {
    AUTH_LOGIN () {
      this.login.business = this.$store.state.isClient
      this.$store.dispatch('AUTH_LOGIN', this.login)
        .then((res) => {
          if (res) {
            if (res.status) {
              if (res.data.data.response && res.data.data.access_token) {
                this.$store.dispatch('TOAST', { show: true, message: 'Logged In' })
                localStorage.setItem('t', res.data.data.access_token)
                this.$store.dispatch('ME')
              } else {
                this.$store.dispatch('TOAST', { show: true, message: res.data.data.message })
              }
            } else {
              this.$store.dispatch('TOAST', { show: true, message: 'Ops, something went wrong' })
            }
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Ops, something went wrong' })
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.auth-page {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  padding: 40px 0px!important;
  overflow-y: auto;
  z-index: 1000000000;
  background: #fafafa;
  scroll-behavior: smooth
}
.bg-primary { background-color: #FF6B00; }
.text-primary { color: #FF6B00; }
.border-primary { border-color: #FF6B00; }
.btn-primary {
    background-color: #FF6B00;
    transition: all 0.3s ease;
}
.btn-primary:hover {
    background-color: #E65A00;
}
</style>
